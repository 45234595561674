import styled, { css } from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 56px;

  ${atMinWidth.xl`
    display: grid;
    grid-template-columns: 320px 1fr;
    gap: 30px; 
  `}
`;

export const InnerWrapper = css`
  ${atMinWidth.lg`
    max-width: 840px;
    padding-left: 35px;
    padding-right: 35px;
  `}

  ${atMinWidth.xl`
    max-width: 1240px;
  `}
`;

export const Sidebar = styled.aside`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${atMinWidth.xl`
      position: sticky;
      top: 120px;
  `}

  .sidebar-heading {
      ${font('display', 'md', '700')}
      color: ${colors.gray[900]};

      ${atMinWidth.md`
          ${font('display', 'lg', '700')}
      `}

      ${atMinWidth.xl`
          ${font('display', 'md', '700')}
      `}
  }
`;

export const Dropdown = styled.div`
  width: 100%;
  display: block;

  ${atMinWidth.xl`
      display: none;
  `}
`;

export const List = styled.ul`
  display: none;
  width: 100%;
  list-style: none;
  padding: 0px;
  margin: 0px;

  ${atMinWidth.xl`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`;

export const ListItem = styled.li`
    width: 100%;
    display: flex;
    align-items: center;

    a {
      width: 100%;
      padding: 16px;
      border-radius: 2px;
      ${font('text', 'md', '400')}
      color: ${colors.gray[700]};
      transition: all 0.2s ease-in-out;
      border-radius: 4px;

      &.active,
      &:hover {
          ${font('text', 'md', '700')}
          color: ${colors.primary[500]};
          background-color: ${colors.primary[50]};
          text-decoration: none;
      }

      &:hover:not(.active) {
        font-weight: 400;
      }
    }
`;

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 32px;

  p {
    ${font('text', 'md', '400')}
    color: ${colors.gray[600]};
  }

  strong {
    ${font('text', 'md', '700')}
    color: ${colors.gray[900]};
  }

  h1, h1 strong {
    ${font('display', 'xl', '700')}
    color: ${colors.gray[900]};
  }

  h2, h2 strong {
    ${font('display', 'lg', '700')}
    color: ${colors.gray[900]};
  }

  h3, h3 strong {
    ${font('display', 'md', '700')}
    color: ${colors.gray[900]};
  }

  h4, h4 strong {
    ${font('display', 'sm', '700')}
    color: ${colors.gray[900]};
  }

  h5, h6 {
    ${font('display', 'xs', '700')}
    color: ${colors.gray[900]};
  }

  ul, ol {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 45px;
  }
`;
